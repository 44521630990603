






































import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProductSpecification as ProductSpecificationI } from '../../../../contexts'

import { tabs } from './ProductSpecification.config'
import { BoxSection, ColumnSection, HeroSection, InciSection, StepSection } from './_partials'
import { MissingSection, ProductSpecificationColors } from './ProductSpecification.contracts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<ProductSpecification>({
  name: 'ProductSpecification',
  components: {
    BoxSection,
    ColumnSection,
    HeroSection,
    InciSection,
    StepSection
  }
})
export class ProductSpecification extends Vue {
  @Prop({ required: true, type: Object })
  public color!: ProductSpecificationColors

  @Prop({ required: true, type: Object })
  public productSpecification!: ProductSpecificationI

  @Prop({ required: false, type: String })
  public productEAN!: string

  @Prop({ required: false, type: String })
  public productSKU!: string

  public activeTab: string = this.translateNavigationTab(tabs.heroSection)
  public tabs = tabs

  public get cssVariables () {
    return {
      '--product-specification-bg-color': this.color.background,
      '--product-specification-nav-tabs': this.color.navTabs,
      '--product-specification-content': this.color.content,
      '--product-specification-heading': this.color.heading,
      '--product-specification-sub-heading': this.color.subHeading,
      '--product-specification-bg-box': this.color.backgroundBox,
      '--product-specification-box-section-content-title': this.color.boxSectionContentTitle,
      '--product-specification-step-section-box-shadow': this.color.stepSectionBoxShadow,
      '--product-specification-step-section-step-bg': this.color.stepSectionStepBackground,
      '--product-specification-step-section-step-color': this.color.stepSectionStepColor
    }
  }

  /**
   * Nav tabs that section's content is not missing
   */
  public get validNavTabs () {
    const validTabs = []
    for (const tab of Object.values(this.navTabs)) {
      if (!tab.missing) {
        validTabs.push(tab)
      }
    }

    return validTabs
  }

  public get navTabs () {
    /**
     * Array of objects containing sections that are missing = there is no content to display
     */
    const missing: MissingSection[] = []
    for (const [key, value] of Object.entries(this.productSpecification)) {
      if (value.items && value.items.length === 0) {
        missing.push({ section: key, heading: value.heading.toLowerCase() })
      }
    }

    return Object.entries(tabs).filter(([k]) => {
      return k in this.productSpecification
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).map(([key, value], index) => {
      const label = this.$t(`front.products.productSpecification.navigation.${value}`)
      if (missing.length > 0) {
        for (const value of Object.values(missing)) {
          if (value.section === key) {
            return {
              missing: true,
              id: label,
              label,
              active: index === 0,
              anchor: this.toAnchorUrl(label.toString())
            }
          }
        }
      }
      return {
        missing: false,
        id: value,
        label,
        active: index === 0,
        anchor: this.toAnchorUrl(value)
      }
    })
  }

  public get tabNavigation () {
    return {
      isDisabled: false,
      isWrapped: true,
      items: this.validNavTabs,
      justify: false
    }
  }

  public toAnchorUrl (name: string): string {
    return name.replace(' ', '-').toLowerCase()
  }

  public translateNavigationTab (key: string): string {
    return this.$t(`front.products.productSpecification.navigation.${key}`).toString()
  }
}

export default ProductSpecification
